<template>
  <div class="content">
    <div class="row" style="background: #2f2f2f ;">
      <div class="text-center col-xl-8 col-sm-12"
      style="background-color: #181717"
      >
        <img
          height="90px"
          style="padding: 10px;margin-top: 20px"
          src="https://imgix.cosmicjs.com/dbcd2d20-afde-11ec-97bc-19d12908cbbe-image.png?auto=format"
          alt="Card image"
        />
        <hr>
        <img v-lazy="logo" height="20px">


        <h1>Let us Tell Our Story</h1>
        <h3 class="tulum-party-subtitle ">THE BEGINNING</h3>
        <p class="text-gray-300">
          We created this website back in on 2018 as a music fan "side project" with a goal in mind.
          <br>
          <br>
          <strong>"Help party-goers in Tulum discover where the party is"</strong>
          <br>
        </p>

        <p>
          Back then, finding parties in Tulum at the right time was a real challenge, as there were limited options and
          the information was often hard to come by.
          We made it our mission to <strong>offer daring party-goers the best happenings Tulum's Techno-House
          scene</strong> had to offer. Today after years of hard work and attentive
          care we can say that anywhere in Tulum parties bear our mark.
        </p>
        <br>


        <img v-lazy="astronaut" height="120px">
        <br>

        <hr>
        <p>
          Since those humble beginnings, over time we have continued to provide party information without stopping and
          bringing together locals and travelers in the Riviera Maya to dance and have fun.
          <br>

        </p>
        <br>
        <div style="background-color: #4A4A4A; border-radius: 15px;">
          <p>
            Our goal has not changed much since then, but the circumstances are now so different it feels like someone
            else’s life, and in a sense, it is.
            <strong>We keep working to fill Tulum parties and help clubs, brands, festivals and the people of Tulum be
              found</strong> by party goers around the globe, and help
            those party people plan their stay in the Riviera Maya and party in Tulum.
          </p>
        </div>
        <hr>
        <h3 class="tulum-party-subtitle ">THE COVID REVOLUTION</h3>
        <p>
          During the onset of the COVID-19 pandemic, our website saw an influx of visitors seeking information and
          guidance on Tulum's party scene.
          <br>

          Brave travelers sought to escape the constraints of lockdowns and curfews and were looking for ways to safely
          enjoy the nightlife Tulum had to offer. As event promoters with a passion for music, we were determined to
          provide reliable and up-to-date information to help these travelers navigate Tulum's lively party scene.
          <strong>Our mission has always been to support and promote the party scene</strong>, and we have continued to
          do so even during these challenging times.
        </p>
        <br>

        <div class="card centered" style="width: 70%;">
          <p>
            <strong>
              This was good too, because Tulum was out of the way, with a small population, so it was difficult for
              anyone to get infected. Tulum party at the time was a place to meet like minded people from the safety of
              home and get through a rough patch.
            </strong>
          </p>

        </div>

        <br>

        <br>

        <p>As a web-based platform, we were able to serve as a hub for underground activity, even in the face of
          restrictions on major media channels and social networks.</p>
        <p>Our work during these challenging times provided us with a sense of purpose and helped us navigate the
          darkness of the COVID pandemic.</p>

        <br>
        <p>
          <strong>
            WHO ARE WE?
          </strong>
          <br>
          <br>

          Originally, we were <strong>just enthusiastic dancers that found ourselves at a party in Tulum.</strong> We
          fell in love with the scene, the groove, the ambience, and we noticed all operators in the city matchmaking
          for tourists and the parties were foreign.
          <strong>We started publishing event dates and information</strong>   without any return for years, then we started selling tickets for small parties, supporting little events until they grew and finding shortcuts for people that
          came into Tulum and didn't know where they wanted to party.

        </p>
<!--        To this day, other than the people living year round in Tulum, we are the only Mexican operators for Tulum-->
<!--        parties and clubs.-->
<!--        We are the only ones that have actually been on the field. We know what you need and want from a party, from-->
<!--        an experience, because we've been there, and on the other side of the counter, so we have learned to give it-->
<!--        to you as fast and furious as you can handle it.-->
        <br>
        <img src="https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png?w=300&h=100&fit=clamp" width="300" height="100">

        <br>
        <hr>
        <p>
          <strong>
            WHY US?
          </strong>
          <br>
          When you want to go to a party in Tulum you might not know what to do or where to go, or maybe you might be
          thinking that you know what you want to see but now you have to take care of everything about the trip.
          Well, <strong>nobody travels to do work, you travel to have fun</strong>. We make your stay fun, and make sure
          you actually get to parties in Tulum that you want to go to, that are right for you and that scratch that
          itch. Look:
          <br>
        </p>

        <ul>
          <li>
            <strong>Logistics</strong>: Press skip on boring and hard work that does nothing for your experience. We
            take care of everything.
          </li>
          <li>
            We offer you <strong>VIP treatment all the way!</strong> Exclusive access, reservations, and bottle service
            everywhere!
          </li>
          <li>
            <strong>In the Know</strong>: Travel agencies send you to a place, we invite you to do what we know you’ll
            like. We know Tulum's party scene inside and out and guide you through it.
          </li>
          <li>
            <strong>Constant support</strong>: We offer you assistance via whatsapp almost all day, and specially when
            you most need it.
          </li>
          <li>
            <strong>Stay in the Flow</strong>: we handle transportation, equipment rentals, and anything else you need
            for a smooth transition between venues.
          </li>
          <li>
            <strong>Personal attention</strong>: We know the place, you tell us what you want and we make it happen.
            It’s all about YOUR experience.
          </li>
          <li>
            <strong>No extra fees!</strong> You get all our services because it’s the right service, not because we want
            to squeeze you something extra.
          </li>
          <li>
            <strong>Vacation planning</strong>: You want something other than to go partying? We make your event happen!
          </li>
        </ul>

        <h3 class="tulum-party-subtitle">HOW DO WE OPERATE?</h3>

<!--        <p>-->
<!--          <strong>Our passion is music, and our objective is to live off our passion.</strong> We want to be able to-->
<!--          have a scene as big and great as possible so we never have to sacrifice the quality of the event at the altar-->
<!--          of profit.-->
<!--          With that focus then, <strong>we work to give extra exposure to small events that we think are worthy</strong>-->
<!--          and obviously promote the big events that are a whole year in the making to blow everybody's gray matter-->
<!--          during-->
<!--          <strong>New Years Eve in Tulum</strong>.-->
<!--          As event promoters, the Tulum Party crew operates with a passion for music and a commitment to providing-->
<!--          top-notch party experiences. The team is small and independent, and we have built our reputation through hard-->
<!--          work and word of mouth.-->
<!--          We believe in creating a genuine and authentic party scene, that's why <strong>our business model is less a-->
<!--          business, more a gamble that party people will keep coming if we keep feeding them awesome parties in-->
<!--          Tulum.</strong>-->
<!--          There you have it… we rely on the quality of our events and the commitment of the DJs, producers and audience-->
<!--          of the party scene.-->
<!--          Is the people that believe in us that help us to spread the word and attract party goers from around the world-->
<!--          to Tulum. We are nothing without the people that share our passion and we know <strong>we are our own project-->
<!--          as much as we are Tulum's property.</strong>-->
<!--        </p>-->
        <br>

        Our recommendations are truly based on data, past experience and party people's feedback.<strong>People believe
        in us because it's an echo of their own experience.</strong>
        <hr>
        <p>
          We sustain our operations through commissions on ticket sales and event reservations. As dedicated promoters
          of the Tulum party scene, we encourage partygoers to purchase tickets and make reservations through us,
          <strong>as this support helps us to keep our mission alive.</strong>
        </p>

        <br>

        <p>
          In addition, <strong>we reinvest a portion of our commissions back into the party scene and the promotion of
          underground artists.</strong>
          This not only helps to support the local music community but also ensures that the party scene in Tulum
          remains vibrant and exciting for years to come.
          We are more than just event promoters, <strong>we are champions of the music and the party scene.</strong>

          <br>

          Our vision involves a growing Tulum, with more variety and the same quality. We take years to cultivate great
          things and talents because we know the best things in life take a little patience to grow.
        </p>
        <p>
          <strong>PARTNERSHIPS</strong>
          <br>

          We are always <strong>looking for promising partners</strong> that share our passion and understand our
          mission. We want to help
          <strong>Brands, Clubs or Festivals</strong> who want to attract a bigger crowd.
          <br>

          We are also looking for <strong>ambassadors</strong> that want to help us grow, register the parties they go
          to and bring back some of this fabulous ambience for all to see.
          If you are passionate about the scene and want to share what you find here in Tulum, we want you!
        </p>
        <hr>
        <br>
        Drop us an Email if you want to become our partner, ambassador or want to
        contribute. We are open to ideas & feedback:
        <br/>

        <a href="mailto:ronnie@tulum.party" class="font-weight-900">Send us an email.</a>
        <br>

        <br>


        <hr>




        <h5>¡Mira mamá! ¡Ya puedo vivir de la música!</h5>


      </div>
      <div class="card col-4 col-xl-4 d-none d-xl-inline">
        <br>
        <h4 class="section-header" style="font-weight: bold">
          <i class="tim-icons icon-chat-33"></i> GET IN TOUCH
        </h4>
        <div class="row">
          <div class="col-sm-12 text-center">
            <p>
              <strong>Want to Say Hi</strong>
               Drop us a line !
            </p>

            <hr>

          </div>
          <div class="col-sm-12 text-center">
            <RsvpViaDialog
              text="INFO"
            ></RsvpViaDialog>
          </div>
        </div>
        <br>

        <section :style="{ background: `linear-gradient(${this.clubColor}, black )` }">

          <h4 class="section-header">
            SOCIAL
          </h4>
          <hr style="height: 2px" :style="{ backgroundColor: this.clubColor }"/>
          <ul>
            <li><a href="https://www.instagram.com/tulum.party/">Instagram</a></li>
            <li><a href="https://www.facebook.com/tulum.party">Facebook</a></li>
          </ul>
        </section>
        <section :style="{ background: `linear-gradient(${this.clubColor}, black )` }">

          <h4 class="section-header">
            <i class="tim-icons icon-map-big"></i>
             OUR PARTNERS
          </h4>
          <hr style="height: 2px" :style="{ backgroundColor: clubColor }"/>
          <ul>
            <li>Grupo Mandala</li>
            <li>Grupo Rosa Negra</li>
            <li>Zamna</li>
            <li>Papaya Playa</li>
            <li>Much More ..</li>
          </ul>
        </section>

      </div>

    </div>
  </div>
</template>

<script>
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog";
export default {
  metaInfo: () => ({
    title: 'TULUM.PARTY | About Us. Who we are?',
    meta: [
      {name: 'robots', content: 'noindex'},
      {
        name: 'description',
        content: 'Tulum Parties, Nightlife and Techno Music.',
      },
      {
        name: 'keywords',
        content:
          'Tulum Party, About Us, Who we are',
      },
    ],
  }),
  data: () => {
    return {
      modal: {},
      clubColor: '#313134',
      astronaut: "https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg",
      followImg: "https://imgix.cosmicjs.com/89de8e60-9a96-11ee-b34c-99d1d89e42eb-IMG5765.jpg",
      logo: 'https://imgix.cosmicjs.com/afd1db60-3abd-11ee-b850-2d3046e40f87-poster.png',
      name: null,
      email: null,
      message: null,
      events: [],
    };
  },
  methods: {},
  components: {
    RsvpViaDialog
  }
};
</script>

<style scoped></style>
